function makesvg( percentage, inner_text = "" ) {

  var abs_percentage = Math.abs( percentage ).toString();
  var percentage_str = percentage.toString();
  var classes = ""

  if (percentage < 0) {
    classes = "danger-stroke circle-chart__circle--negative";
  } else if (percentage > 0 && percentage <= 30) {
    classes = "success-stroke";
  } else {
    classes = "success-stroke";
  }

  var svg = '<svg class="circle-chart" viewbox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">' + '<circle class="circle-chart__background" cx="16.9" cy="16.9" r="15.9" />' + '<circle class="circle-chart__circle ' + classes + '"' + 'stroke-dasharray="' + abs_percentage + ',100"    cx="16.9" cy="16.9" r="15.9" />' + '<g class="circle-chart__info">' + '   <text class="circle-chart__percent" x="17.9" y="15.5">' + percentage_str + '%</text>';

  if (inner_text) {
    svg += '<text class="circle-chart__subline" x="16.91549431" y="22">' + inner_text + '</text>'
  }

  svg += ' </g></svg>';

  return svg
}

export function circlechart() {
  // Get all elements with class "circlechart"
  let elements = document.querySelectorAll( '.circlechart' );
  elements.forEach( function ( el ) {
    let percentage = el.getAttribute( 'data-percentage' );
    let inner_text = el.textContent;
    el.innerHTML = makesvg( percentage, inner_text );
  } );
}