import { useLocation } from "react-router-dom";

export const Portfolio = () => {
  const location = useLocation();
  return (
    <section className={location.pathname === "/portfolio" ? "oliva-intro tabcontent tab-active" : "oliva-intro"}>
      <div className="intro_main">
        <h2 className="intro font_w_font_s fade_up">Projects</h2>
      </div>
      <h2 className="moswa font_w_font_s fade_up">My projects</h2>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Fitness Playground</h3>
          <a className="university font_w_font_s1 underline" href="https://fitness-playground.vercel.app"
             target="_blank" rel="noopener noreferrer">https://fitness-playground.vercel.app
          </a>
        </div>
      </div>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Young Professionals Rotaract</h3>
          <a className="university font_w_font_s1 underline" href="https://www.rotaractnt.com"
             target="_blank" rel="noopener noreferrer">https://www.rotaractnt.com
          </a>
        </div>
      </div>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Northern Trade Solutions</h3>
          <a className="university font_w_font_s1 underline" href="https://ntsnt.com.au"
             target="_blank" rel="noopener noreferrer">https://ntsnt.com.au
          </a>
        </div>
      </div>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Top End websites</h3>
          <a className="university font_w_font_s1 underline" href="https://www.topendwebsites.com.au"
             target="_blank" rel="noopener noreferrer">https://www.topendwebsites.com.au
          </a>
        </div>
      </div>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Lawrence Air</h3>
          <a className="university font_w_font_s1 underline" href="https://www.lawrenceair.com.au"
             target="_blank" rel="noopener noreferrer">https://www.lawrenceair.com.au
          </a>
        </div>
      </div>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Northern Territory Momineen</h3>
          <a className="university font_w_font_s1 underline" href="https://ntmomineen.com.au"
             target="_blank" rel="noopener noreferrer">https://ntmomineen.com.au
          </a>
        </div>
      </div>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">CDU Business Students Association</h3>
          <a className="university font_w_font_s1 underline" href="https://www.cdubsa.com"
             target="_blank" rel="noopener noreferrer">https://www.cdubsa.com
          </a>
        </div>
      </div>

    </section>
  )
}