import { useLocation } from "react-router-dom";
import mattHarry from "images/references/mattharry.jpeg";
import mukesh from "images/references/mukesh.jpeg";
import nicolette from "images/references/nicolette.jpeg";

export const References = () => {
  const location = useLocation();
  return (
    <section className={location.pathname === "/projects" ? "oliva-intro tabcontent tab-active" : "oliva-intro"}>
      <div className="intro_main">
        <h2 className="intro font_w_font_s fade_up">references</h2>
      </div>
      <h2 className="moswa font_w_font_s fade_up">What they say about me</h2>
      <div className="testimonials_card">
        <div>
          <div className="slider_contain_main">
            <div className="img_text">
              <img className="client_img" src={mattHarry}
                   alt="testimonial_img"
                   width="70px"/>
              <div>
                <h4 className="client_name font_w_font_s">Matt Harry</h4>
                <p className="client_roll font_w_font_s1">Digital Asset and Financial Markets, Crypto
                  Funds Management, Sales and Distribution - AFSL Responsible Manager</p>
              </div>
            </div>
          </div>
          <h3 className="placerat font_w_font_s1 mt-4">During his time at Cloudbreak Asset Management, Frank
            demonstrated a very strong mathematical ability and high levels of proficiency across many
            aspects of the business including coding, auditing, reporting, security, technical analysis
            and trading. He was a diligent worker and a very well liked member of the team. I would have
            no issue in recommending Frank for future employment.
          </h3>
        </div>
        <br/>
        <div>
          <div className="slider_contain_main">
            <div className="img_text">
              <img className="client_img" src={mukesh}
                   alt="testimonial_img2"
                   width="70px"/>
              <div>
                <h4 className="client_name font_w_font_s">Mukesh Garg</h4>
                <p className="client_roll font_w_font_s1">Associate Professor of Accounting, Department
                  of Accounting, Monash University</p>
              </div>
            </div>
          </div>
          <h3 className="placerat font_w_font_s1 mt-4">I have known Frank over the last 10 months in the
            capacity
            of a lecturer at Monash University. Frank’s academic performance proves high level of
            motivation and intelligence as the program is very rigorous and requires a lot of commitment
            on a student’s part. Besides this I have found Frank to be a matured thinker when dealing
            with other students in the subject and presents very professionally. Frank is also very soft
            spoken, friendly and diligent. This is my personal experience during conversations on
            numerous occasions. Frank has demonstrated good communication skills and works effectively
            in a team. I have confidence that Frank will perform equally well as an employee and will
            very easily adapt to any work environment.
          </h3>
        </div>
        <br/>
        <div>
          <div className="slider_contain_main">
            <div className="img_text">
              <img className="client_img" src={nicolette}
                   alt="testimonial_img3"
                   width="70px"/>
              <div>
                <h4 className="client_name font_w_font_s">Nicolette van Straaten</h4>
                <p className="client_roll font_w_font_s1">ELICOS / ESL / Academic English educator with
                  a background in education management</p>
              </div>
            </div>
          </div>
          <h3 className="placerat font_w_font_s1 mt-4">I have gotten to know Frank as an extremely motivated
            and
            very diligent student, always asking for feedback and looking for ways to improve even
            further. Furthermore, his excellent work ethic had a positive influence on the entire className
            and Frank was able to work together successfully with all of his fellow students. I would
            highly recommend him for any position, as Frank sets ambitious goals for himself and is
            willing to go above and beyond to reach these goals.
          </h3>
        </div>
      </div>
    </section>
  )
}