import { useState, useEffect } from 'react';

export const Headliner = () => {
  // designer text
  const texts = ["Front end Developer", "React Developer", "Freelancer"]
  const [currentIndex, setCurrentIndex] = useState( 0 );
  useEffect( () => {
    const interval = setInterval( () => {
      setCurrentIndex( ( currentIndex + 1 ) % texts.length );
    }, 600 * 3 );
    return () => {
      clearInterval( interval );
    };
  }, [currentIndex,] );
  return ( <h1 className="designer">{texts[ currentIndex ]}</h1> );
};