import myExperience from 'images/myexperience.png'
import downArrow from 'images/down_arrow.svg'
import { Link, useLocation } from "react-router-dom";

export const Home = () => {
  const location = useLocation();
  return (
    <section
      className={location.pathname === "/" ? "oliva-intro tabcontent tab-active py-auto centerDiv" : "oliva-intro py-auto centerDiv"}>
      <div className="intro_main">
        <h2 className="intro font_w_font_s fade_up">intro</h2>
      </div>
      <h2 className="moswa font_w_font_s fade_up">Hi, I'm Frank Espinoza</h2>
      <h3 className="passionate font_w_font_s1 fade_up">I'm a highly motivated developer specializing in Front End
        development with React and JavaScript. I'm highly organized, learn fast and work well under
        pressure. My areas of specialization are React, NextJS, JavaScript, Typescript and Sass.
      </h3>
      <div className="button_circle_group">
        <div>
          <button className="download_cv font_w_font_s"
                  onClick={() => window.open( 'pdf/frank_CV.pdf' )}
          >
            <i className="ri-download-cloud-2-line"></i>Download CV
          </button>
        </div>
        <div className="circular_text_main ">
          <Link to={"/experience"} className="down_arrow_main position-relative">
            <img className="circular_text" src={myExperience} alt="circular_text"/>
            <img className="down_arrow" src={downArrow} alt="down_arrow"/>
          </Link>
        </div>
      </div>
    </section>
  )
}
