import {
  createBrowserRouter, RouterProvider
} from 'react-router-dom';

import "css/swap.css"
import "css/boostrap.min.css"
import "css/magnific-popup.min.css"
import "css/slick.css"
import "css/style.css"
import "css/media_query.css"
import { Home } from "components/Home";
import { About } from "components/About";
import { Contact } from "components/Contact";
import { Layout } from "components/Layout";
import { Experience } from "components/Experience";
import { References } from "components/References";
import { Skills } from "components/Skills";
import { Portfolio } from "components/Portfolio";
import { Education } from "components/Education";

const router = createBrowserRouter(
  [{
    element: <Layout/>,
    children: [
      {
        path: "/", element: <Home/>,
      }, {
        path: "/about", element: <About/>,
      }, {
        path: "/education", element: <Education/>,
      }, {
        path: "/experience", element: <Experience/>,
      }, {
        path: "/portfolio", element: <Portfolio/>,
      }, {
        path: "/skills", element: <Skills/>,
      }, {
        path: "/references", element: <References/>,
      }, {
        path: "/contact", element: <Contact/>,
      }
    ]
  }]
);


const App = () => <RouterProvider router={router}/>;

export default App;
