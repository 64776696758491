import { Link, Outlet, useLocation } from 'react-router-dom';
import home_icon from "images/home_icon.svg";
import about_icon from "images/about_icon.svg";
import university2 from "images/university2.svg";
import resume_icon from "images/resume_icon.svg";
import portfolio_icon from "images/portfolio_icon.svg";
import skills_icon from "images/skills_icon.svg";
import testimonial_icon from "images/testimonial_icon.svg";
import contact_icon from "images/contact_icon.svg";
import { Preloader } from "components/Preloader";
import frankImg from "images/frank/frank-purple.png";
import { useEffect } from "react";
import { applyCursorEffects } from "javascript/cursor";
import { applyParticleAnimation } from "javascript/dot_particle";
import { circlechart } from "javascript/progressCircle";
import { Headliner } from "components/Headliner";
import line_menu from "images/line-menu.png";
import { openNav, closeNav } from "javascript/helpers";
import { Analytics } from "@vercel/analytics/react"

const navItems = [
  { link: "/", icon: home_icon, label: "Home", alt: "home_icon" },
  { link: "/about", icon: about_icon, label: "About Me", alt: "about_icon" },
  { link: "/education", icon: university2, label: "Education", alt: "resume_icon" },
  { link: "/experience", icon: resume_icon, label: "Experience", alt: "resume_icon" },
  { link: "/portfolio", icon: portfolio_icon, label: "Portfolio", alt: "portfolio_icon" },
  { link: "/skills", icon: skills_icon, label: "Skills", alt: "skills_icon" },
  { link: "/references", icon: testimonial_icon, label: "References", alt: "testimonial_icon" },
  { link: "/contact", icon: contact_icon, label: "Contact", alt: "contact_icon" },
];
const observer = new IntersectionObserver( ( entries ) => {
  entries.forEach( ( entry ) => {
    if ( entry.isIntersecting ) {
      entry.target.classList.add( 'show' );
    } else {
      entry.target.classList.remove( 'show' );
    }
  } );
} );

export const Layout = () => {
  const location = useLocation();

  useEffect( () => {
    const storedMode = "dark"
    if ( storedMode === "dark" ) {
      document.body.classList.add( "dark-mode" );
      var sunIcon = document.getElementById( "sunIcon" );
      var moonIcon = document.getElementById( "moonIcon" );
      sunIcon.classList.add( "hidden" );
      moonIcon.classList.remove( "hidden" );
    }
    setTimeout( function () {
      var elements = document.querySelectorAll( '.wrapper' );
      for ( var i = 0; i < elements.length; i++ ) {
        elements[ i ].classList.add( 'loaded' );
      }
    }, 1000 );
    applyCursorEffects();
    applyParticleAnimation();
    circlechart();

  }, [] );
  useEffect( () => {
    const hiddenElements = document.querySelectorAll( '.fade_up' );
    hiddenElements.forEach( ( el ) => observer.observe( el ) );
  }, [location] );

  return (
    <div className="all_page" id="page">
      <header>
        <div className="sidebar close">
          <ul className="nav-links icetab-container" id="icetab-container">
            {navItems.map( ( item, idx ) => (
              <li key={idx} className={location.pathname === item.link ? "icetab current-tab" : "icetab"}>
                <Link className="nav_menu_icon_line" to={item.link}>
                  <img className="nav_menu_icon" src={item.icon} alt={item.alt}/>
                </Link>
                <ul className="sub-menu blank">
                  <li><Link className="link_name" to={item.link}>{item.label}</Link></li>
                </ul>
              </li>
            ) )}
          </ul>
        </div>
        <div className="toggle" onClick={openNav}>
          <img className="line-menu" src={line_menu} alt="line-menu"/>
        </div>
        <div id="mySidenav" className="sidenav">
          <div className="closebtn" onClick={closeNav}>&times;</div>
          <ul className="sidescreen_sub icetab-container" id="icetab-container2">
            {navItems.map( ( item, idx ) => (
              <li key={idx} className={location.pathname === item.link ? "icetab current-tab" : "icetab"}>
                <Link className="icons2" to={item.link}>
                  <img className="nav_menu_icon sidenav_img" src={item.icon} alt={item.alt}/>{item.label}
                </Link>
              </li>
            ) )}
          </ul>
        </div>
      </header>
      <div className="social_media_main" id="social_media_main">
        <ul className="social_media">
          <li><a href="https://www.linkedin.com/in/frank-espinoza" target="blank">LinkedIn</a></li>
          <li><a href="https://github.com/Hachondeoro" target="blank">GitHub</a></li>
        </ul>
      </div>
      <Preloader/>
      <Analytics/>
      <main className="card_section" id="card_main">
        <div className="all_page" id="page">
          <section className="card_section" id="card_main">
            <div className="olivia_bio" id="oliva_details">
              <div className="imgBox" style={{textAlign:"center"}}>
                <img className="olivia-img" src={frankImg} alt="frank-img"/>
              </div>
              <div className="bio_contain">
                <h1 className="olivia_moswa">Frank Espinoza</h1>
                {/*<h2 className="designer font_w_font_s1 overflow-hidden"></h2>*/}
                <Headliner/>
                <a href="mailto:frank1392@gmail.com">
                  <button className="download_cv hire_me font_w_font_s">
                    <i className="ri-at-line"></i> Contact Me
                  </button>
                </a>
              </div>
            </div>
            <div className="right_card_main icetab-content" id="icetab-content">
              <Outlet/>
            </div>
          </section>
        </div>
      </main>
    </div>
  )
}