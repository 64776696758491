import { useLocation } from "react-router-dom";
import gmail from "images/gmail.svg";
import map from "images/map.svg";

export const Contact = () => {
  const location = useLocation();
  return (
    <section className={location.pathname === "/contact" ? "oliva-intro tabcontent tab-active" : "oliva-intro"}>
      <div className="intro_main">
        <h2 className="intro fade_up">contact me</h2>
      </div>
      <div>
        <h3 className="moswa touch">Get in touch!</h3>
        <div className="contact_number_main">
          <img src={gmail} alt="gmail"/>
          <h4 className="contact_number"><a href="mailto:frank1392@gmail.com">frank1392@gmail.com</a></h4>
        </div>
        <div className="contact_number_main">
          <img src={map} alt="map"/>
          <h4 className="contact_number_address">
            Darwin City NT Australia
          </h4>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6160.282225672139!2d130.84203005296231!3d-12.459942440339502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sau!4v1709531018660!5m2!1sen!2sau"
        style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade">
      </iframe>
    </section>
  )
}