import { useLocation } from "react-router-dom";

export const Education = () => {
  const location = useLocation();
  return (
    <section className={location.pathname === "/education" ? "oliva-intro tabcontent tab-active" : "oliva-intro"}>
      <div className="intro_main">
        <h2 className="intro font_w_font_s fade_up">Resume</h2>
      </div>
      <h2 className="moswa font_w_font_s fade_up">Education</h2>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Diploma of Business & Advanced Diploma of Leadership and
            Management</h3>
          <h4 className="university font_w_font_s1">International House Business College, Darwin Australia /
            2020-2021
          </h4>
        </div>
      </div>
      <div className="education position-relative education_space">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Master of Banking and Finance</h3>
          <h4 className="university font_w_font_s1">Monash University, Melbourne Australia / 2016 - 2017</h4>
        </div>
      </div>
      <div className="education position-relative">
        <div className="side_circle_ring fade_up">
          <div className="small_yellow_border">
            <div className="small_yellow_circle"></div>
          </div>
        </div>
        <div className="small_yellow_border_main fade_up">
          <h3 className="bachelor font_w_font_s1">Bachelor of Business and Commerce</h3>
          <h4 className="university font_w_font_s1">Universidad Catolica San Pablo, Peru / 2010 - 2014</h4>
        </div>
      </div>
    </section>
  )
}