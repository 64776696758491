import { useLocation } from "react-router-dom";

export const Experience = () => {
  const location = useLocation();
  return (
    <div>
      <section className={location.pathname === "/experience" ? "oliva-intro tabcontent tab-active" : "oliva-intro"}>
        <div className="intro_main">
          <h2 className="intro font_w_font_s fade_up">Resume</h2>
        </div>
        <h2 className="moswa font_w_font_s fade_up">Work experience</h2>
        <div className="education position-relative education_space">
          <div className="side_circle_ring fade_up">
            <div className="small_yellow_border">
              <div className="small_yellow_circle"></div>
            </div>
          </div>
          <div className="small_yellow_border_main fade_up">
            <div className="envato_group_main">
              <div>
                <h3 className="bachelor font_w_font_s1">Software Engineer</h3>
                <h4 className="company font_w_font_s1">Charles Darwin University / 2022 April – 2024 March</h4>
              </div>
            </div>

            <ul className="experience_resume">
              <li>Led front-end development for the CDU student portal, a standalone site built with React,
                TypeScript, and NextJS. With seamless integration of various university services, students could monitor
                upcoming events, view and manage their schedules, and interact with advanced features designed to
                enhance their educational experience.
              </li>
              <li>Led the development of the Communications Library, a standalone solution built from scratch using
                Microsoft Power Platform (Power Apps and Power Automate), and SharePoint Framework (SPFX). The
                innovative library streamlined the process of requesting, approving, drafting, and recording
                communications to students, enhancing student communication by 40%.
              </li>
            </ul>

          </div>
        </div>
        <div className="education position-relative education_space">
          <div className="side_circle_ring fade_up">
            <div className="small_yellow_border">
              <div className="small_yellow_circle"></div>
            </div>
          </div>
          <div className="small_yellow_border_main fade_up">
            <div className="envato_group_main">
              <div>
                <h3 className="bachelor font_w_font_s1">Associate Applications Developer</h3>
                <h4 className="company font_w_font_s1">Cloudstreet Consulting / 2021 December – 2023 July</h4>
              </div>
            </div>
            <ul className="experience_resume">
              <li>Developed and deployed a custom chatbot aimed at training prospective Australian medical students,
                leading to a 35% increase in user engagement and a 20% decrease in common customer inquiries. This was
                accomplished by leveraging ChatGPT and IBM Watson language models in conjunction with React, Express,
                Node.js, AWS S3, and AWS Amplify technologies.
              </li>
              <li>Collaborated with the DevOps team to rewrite the entire legacy chatbot, transiting from a Java based
                application to React for the front-end and Node.js for the backend.
              </li>
              <li>Full-time role held until March 2022, transitioned to part-time consulting from April 2022.
              </li>
            </ul>
          </div>
        </div>
        <div className="education position-relative education_space">
          <div className="side_circle_ring fade_up">
            <div className="small_yellow_border">
              <div className="small_yellow_circle"></div>
            </div>
          </div>
          <div className="small_yellow_border_main fade_up">
            <div className="envato_group_main">
              <div>
                <h3 className="bachelor font_w_font_s1">Freelance Web Developer</h3>
                <h4 className="company font_w_font_s1">Self employed / 2021 January – 2022 March</h4>
              </div>
            </div>
            <ul className="experience_resume">
              <li>Developed highly responsive websites using React, Next.js, TypeScript, GraphQL and CSS, with Vercel
                deployments for multiple organisations and Non-For-Profits across the Northern Territory, Australia.
              </li>
              <li>Regularly used React, Next.js, and Git to build, manage and version control the websites I created,
                showcasing a commitment to ongoing skill development and best practices in web development.
              </li>
            </ul>
          </div>
        </div>
        <div className="education position-relative education_space">
          <div className="side_circle_ring fade_up">
            <div className="small_yellow_border">
              <div className="small_yellow_circle"></div>
            </div>
          </div>
          <div className="small_yellow_border_main fade_up">
            <div className="envato_group_main">
              <div>
                <h3 className="bachelor font_w_font_s1">Web Developer</h3>
                <h4 className="company font_w_font_s1">Kindness Shake / 2020 June – 2022 March</h4>
              </div>
            </div>
            <ul className="experience_resume">
              <li>Collaboratively developed and maintained the website for an international students charity based in
                the Northern Territory. Employed React, Next.js, GraphQL, Bootstrap, and SASS to implement features
                enhancing user engagement
              </li>
              <li>Implemented connection to a Content Management System (CMS) and a tailored membership platform,
                enhancing user experience and fostering increased member engagement for Northern Territory members.
              </li>
            </ul>
          </div>
        </div>
        <div className="education position-relative education_space">
          <div className="side_circle_ring fade_up">
            <div className="small_yellow_border">
              <div className="small_yellow_circle"></div>
            </div>
          </div>
          <div className="small_yellow_border_main fade_up">
            <div className="envato_group_main">
              <div>
                <h3 className="bachelor font_w_font_s1">Investment Analyst</h3>
                <h4 className="company font_w_font_s1">Cloudbreak Asset Management / 2017 August – 2020
                  Jun</h4>
              </div>
            </div>
            <ul className="experience_resume">
              <li>Conducted portfolio modelling and analysis using R, identifying profitable investment opportunities in
                the cryptocurrency space.
              </li>
              <li>Utilized advanced JavaScript-based scripting language tools for backtesting, improving the accuracy of
                our fund trading strategies.
              </li>
              <li>Employed advanced Excel modelling techniques to manage fund positions, track portfolio holdings, and
                monitor liquidity positions.
              </li>
              <li>Exceeded performance benchmarks in 2018, growing portfolio value by over 50%, outpacing all Australian
                cryptocurrency funds.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}