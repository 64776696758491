import { useLocation } from "react-router-dom";

export const About = () => {
  const location = useLocation();
  return (
    <section className={location.pathname === "/about" ? "oliva-intro tabcontent tab-active" : "oliva-intro"}>
      <div className="intro_main">
        <h2 className="intro font_w_font_s fade_up">about me</h2>
      </div>
      <h2 className="moswa font_w_font_s fade_up">Developer based in Darwin, NT</h2>
      <h3 className="passionate font_w_font_s1 fade_up">Since migrating to Australia from Latin America in 2015, I
        have currently embraced life in Darwin, Northern Territory, a paradisiac place blessed with a
        tropical climate. Formerly a professional in the financial industry, I shifted gears towards
        front-end
        development 4 years ago.
      </h3>
      <h3 className="passionate font_w_font_s1 fade_up">I have since delved into numerous projects, leveraging my
        skills for different organizations and successfully crafting digital solutions that cater to both
        business and
        consumer needs. I am naturally analytical, continually advancing my technical skills through solving
        one programming problem at a time.
      </h3>
      <div className="about_counter fade_up">
        <div className="counter">
          <h3 className="counter2 count font_w_font_s" data-count="4">4+</h3>
          <h3 className="experince font_w_font_s1 ">YEARS OF EXPERIENCE</h3>
        </div>
        <div className="counter">
          <h3 className="counter2 count font_w_font_s" data-count="10">10+</h3>
          <h3 className="experince">PROJECTS COMPLETED IN NT, AUSTRALIA</h3>
        </div>
      </div>
    </section>
  )
}