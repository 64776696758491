import { useLocation } from "react-router-dom";
import react from "images/react.png";
import typescript from "images/typescript.png";
import nextjs from "images/nextjs.png";
import cssImage from "images/css.png";

export const Skills = () => {
  const location = useLocation();
  return (
    <section className={location.pathname === "/skills" ? "oliva-intro tabcontent tab-active" : "oliva-intro"}>
      <div className="intro_main">
        <h2 className="intro font_w_font_s fade_up">my skills</h2>
      </div>
      <h2 className="moswa font_w_font_s fade_up">Software Stack</h2>


      <div className="skill_circle_main">
        <div className="skill_circle fade_up">
          <div className="hard_skill_group">
            <img src={react} alt="react" width="80%"/>
          </div>
          <h4 className="subject font_w_font_s1">React</h4>
        </div>
        <div className="skill_circle fade_up">
          <div className="hard_skill_group">
            <img src={typescript} alt="typescript" width="60%"/>
          </div>
          <h4 className="subject font_w_font_s1">TypeScript</h4>
        </div>
        <div className="skill_circle fade_up">
          <div className="hard_skill_group">
            <img src={nextjs} alt="nextjs" width="80%"/>
          </div>
          <h4 className="subject font_w_font_s1">NextJS</h4>
        </div>
        <div className="skill_circle fade_up">
          <div className="hard_skill_group">
            <img src={cssImage} alt="css" width="50%"/>
          </div>
          <h4 className="subject font_w_font_s1">CSS</h4>
        </div>
      </div>
      <h3 className="lan_skill font_w_font_s fade_up mt-5">Language Skills</h3>
      <div className="skill_circle_main">
        <div>
          <div className="circlechart" data-percentage="100"></div>
          <h2 className="subject">English</h2>
        </div>
        <div>
          <div className="circlechart" data-percentage="100"></div>
          <h2 className="subject">Spanish</h2>
        </div>
      </div>
    </section>
  )
}