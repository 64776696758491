function myFunction() {
  var element = document.body;
  element.classList.toggle( "dark-mode" );
  var sunIcon = document.getElementById( "sunIcon" );
  var moonIcon = document.getElementById( "moonIcon" );
  if ( element.classList.contains( "dark-mode" ) ) {
    sunIcon.classList.add( "hidden" );
    moonIcon.classList.remove( "hidden" );
    localStorage.setItem( "mode", "dark" );
  } else {
    moonIcon.classList.add( "hidden" );
    sunIcon.classList.remove( "hidden" );
    localStorage.setItem( "mode", "light" );
  }
}

export const Preloader = () => {
  return (
    <div>
      <div className="wrapper">
        <div id="loader-wrapper">
          <div id="loader">
            <svg viewBox="0 0 1320 300">
              <text x="50%" y="50%" dy=".35em" textAnchor="middle">Frank</text>
            </svg>
          </div>
          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div>
      </div>
      <canvas id="canvas"></canvas>
      <div className="cursor"></div>
      <div className="dark_toggle">
        <div className="ul-header">
          {/*<a className="mode" title="Toggle Mode">*/}
          <a className="mode" onClick={myFunction} title="Toggle Mode">
            <div className="icon-container">
              <i id="sunIcon" className="fa-solid fa-sun fa-spin icon"></i>
              <i id="moonIcon" className="fa-solid fa-moon icon hidden"></i>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}