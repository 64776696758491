function openNav() {
  var side = document.getElementById( "mySidenav" );
  if (side.style.width === "300px") {
    side.style.width = "0px";
  } else {
    side.style.width = "300px";
  }
}

function closeNav() {
  document.getElementById( "mySidenav" ).style.width = "0";
}

export { openNav, closeNav };